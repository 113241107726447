

(function(){

  //load paths
  let site_content = document.getElementById("site_content");
  //let bodyBG = document.getElementsByTagName("BODY")[0];


  // function bgColour(){

  //   let num = Math.floor((Math.random() * 4) + 1);
  //   if (num === 1){
  //     bodyBG.classList.add("colour1");
  //   } else if (num === 2) {
  //     bodyBG.classList.add("colour2");
  //   } else if (num === 3) {
  //     bodyBG.classList.add("colour3");
  //   } else if (num === 4) {
  //     bodyBG.classList.add("colour4");
  //   }

  // }
  //no colour change
  //bgColour();



  let getContent = function(){

    fetch("https://randywalton.ca/headless-cms/api/collections/get/nine_x_projects?token=3880e75496efb98ddc147d71f677f3")
    .then(response => response.json())
    .then(array => {
        //console.log(array.entries);
        buildProjects.init(array.entries);
     })
     .catch(error => { 
          console.log(error);
     });

  };
  
  getContent();


  let buildProjects = (function () {

    let init = function (jsonObj) {
      let projects = jsonObj;
      //console.log(projects);
      _render(projects);

    };

    let _render = function (projects) {

      projects.forEach(project => {
        //console.log(project);

        let active_state = project.status;

        if (active_state === "Published"){
          //create an article
          let myArticle = document.createElement('article');
          //add a class name
          myArticle.className = 'content_block';


          //Add the titles
          let createTitle = _getTitle(project.title);
          myArticle.appendChild(createTitle); //add title to article

          
          //let createImg = _getImage(project.title, project.project_image.path);

          //Add the content
          let createContent = _addContent(
            project.subtitle, 
            project.overview, 
            project.url, 
            project.url_label,
            project.title, 
            project.project_image.path
          );
          myArticle.appendChild(createContent); //add title to article

          site_content.appendChild(myArticle); //add article to main container
        }

      });

    };

    let _getImage = function (title, image) {
      //build image
      
      const projectImg = document.createElement('img');

      const figure = document.createElement('figure');

      projectImg.src = `https://www.randywalton.ca${image}`;
      projectImg.setAttribute('alt', title);
      figure.classList.add("proj-image");

      figure.appendChild(projectImg);

      return figure;

    };


    let _getTitle = function (title) {
      //build title
      let h2 = document.createElement('h2');

      let totalWords = title;
     
      //seperate the first word away from the title
      let lastWords = totalWords.substr(totalWords.indexOf(" ") + 1);
      let firstWord = totalWords.replace(/ .*/,'');

      h2.innerHTML = `<span>${firstWord}</span> <span>${lastWords}</span>`;

      return h2;

    };

    let _addContent = function (subtitle, content, url, label, title, img) {

      //console.log(content);
      //build content
      let content_section = document.createElement('section');

      //console.log(img);
      //Add image
      if (img !== undefined) {
        let imgLink = document.createElement('a');
        imgLink.href = url;
        let createImg = _getImage(title, img);
        
        imgLink.append(createImg);
        content_section.appendChild(imgLink);
      }

      let article = document.createElement('article');
      article.innerHTML = content;

      
      //build button
      let button = document.createElement('a');
      //add a class name
      button.className = 'enter_btn';

      if (label === '') {
        button.textContent = 'View Project';
      } else {
        button.textContent = label;
      }
     
      button.setAttribute('href', url);

      //add h3 title
      article.insertAdjacentHTML('afterbegin', `<h3>${subtitle}</h3>`)
 
      content_section.append(article, button); //add all elements



      return content_section;

    };

    return {
      init: init
    };

  })();


/*
  function buildProjectImages() {

    let preloaded = 0
  
      function preLoader(e) {
          for (var i = 0; i < singleProject.gallery.length; i++) {
              var tempImage = new Image();
  
              tempImage.addEventListener("load", progress, true);
              tempImage.src = `https://www.randywalton.ca${singleProject.gallery[i].path}`;
          }
          //console.log(preloaded, singleProject.gallery);
      }
  
      function progress() {
          preloaded++;
  
          if (preloaded == singleProject.gallery.length) {
              appendImages();
              //console.log(preloaded);
          }
      }
      preLoader();
  
      
  
      function appendImages() {
        let counter = 1;// for css style (grid placement)
  
        singleProject.gallery.forEach(image => {
  
          //console.log(image.meta.title);
    
          const projectImg = document.createElement('img');
          projectImg.src = `https://www.randywalton.ca${image.path}`;
          projectImg.setAttribute('alt', image.meta.title);
          projectImg.classList.add("proj-image" + counter);
          //projectImg.width = '800';
          //projectImg.height = '600';
          workGrid.append(projectImg);
    
          counter++
        });
      }
  
      
  
  }
  */

})();
